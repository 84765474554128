import Headline from '../../../../shared-kernel/components/headline/Headline';
import Button from '../../../../shared-kernel/components/button/Button';
import { useTranslation } from 'react-i18next';
import './language-selection.scss';
import Card from '../../../../shared-kernel/components/card/Card';
import { useContext, useState } from 'react';
import { AppContext } from '../../../../application/contexts/AppContext';
import { RegistrationContext } from '../../contexts/RegistrationContext';
import { useNavigate } from 'react-router-dom';
import Grid from '../../../../shared-kernel/components/grid/Grid';
import Column from '../../../../shared-kernel/components/grid/Column';
import Radio from '../../../../shared-kernel/components/radio/Radio';

const LanguageSelection = () => {
    const { t, i18n } = useTranslation();
    const { Mission } = useContext(AppContext);
    const { updateLocale } = useContext(RegistrationContext);
    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedLocale, setSelectedLocal] = useState<string>();

    const localeSelectHandler = (locale: string) => {
        updateLocale(locale);
        setSelectedLocal(locale);
        i18n.changeLanguage(locale);
        setIsDisabled(false);
    };

    const confirmHandler = () => {
        navigate('/registration/welcome');
    };

    return (
        <div className={'language-selection'}>
            <Headline type={'h3'}>{t('domain.registration.language-selection.subTitle')}</Headline>

            <Headline type={'h1'} hasMarginTop={true}
                      hasBorder={false}>{t('domain.registration.language-selection.form.title')}</Headline>

            <Grid>
                {Mission?.locales.map((locale) => (
                    <Column key={locale} columns={6}>
                        <Card key={locale} className={(selectedLocale === locale ? 'card--selected' :'')}>
                            <div className={'card__body-text'}>
                                {t(`domain.registration.language-selection.choice-${locale}`)}
                            </div>

                            <Radio
                                onChange={() => localeSelectHandler(locale)}
                                value={locale}
                                isChecked={selectedLocale === locale}
                                groupName={'language-selection'}
                                label={locale.toUpperCase()} />
                        </Card>
                    </Column>
                ))}
            </Grid>

            <Button buttonType={'primary'} onClick={confirmHandler} disabled={isDisabled}>
                {t('domain.registration.language-selection.confirm')}
            </Button>
        </div>
    );
};

export default LanguageSelection;
