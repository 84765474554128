import './header.scss';
import UserCard from '../user-card/UserCard';
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

const Header = () => {
    const { User, Mission } = useContext(AppContext);

    return (
        <header className={'header grid-container'}>
            <div className='column-1 header__col'>
                <img className={'header__brand'} src={Mission?.logo} alt={''} />
            </div>
            <div className='offset-11-column-13'>
                {User && <UserCard />}
            </div>
        </header>
    );
};

export default Header;
