import React, { PropsWithChildren } from 'react';
import './card.scss';
import Tagline from '../tagline/Tagline';
import classNames from 'classnames';

type CardHeaderProps = {
    icon?: string
    onClick?: () => void
}

const CardHeader = (props: PropsWithChildren<CardHeaderProps>) => {
    const clickHandler = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    const classes = classNames('card__header', {
        'card__header--clickable': !!props.onClick,
    });

    return (
        <div className={classes} onClick={clickHandler}>
            {props.icon && <img src={props.icon} className={'card__header-icon'} alt={''} />}

            <Tagline>{props.children}</Tagline>
        </div>
    );
};

type CardProps = {
    className?: string
}

const Card = (props: PropsWithChildren<CardProps>) => {
    return (
        <div className={`card ${props.className}`}>
            {props.children}
        </div>
    );
};

export default Card;

export { CardHeader };
