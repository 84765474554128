import Navigation from '../../components/navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Column from '../../../../shared-kernel/components/grid/Column';
import OutroImage from '../../assets/images/DiveOutro.svg';
import Headline from '../../../../shared-kernel/components/headline/Headline';
import Text from '../../../../shared-kernel/components/text/Text';
import Button from '../../../../shared-kernel/components/button/Button';
import Grid from '../../../../shared-kernel/components/grid/Grid';

const ContinueDive = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <Navigation />

            <Grid>
                <Column columns={6}>
                    <img className={'resized-img'} src={OutroImage} alt={''} />
                </Column>
                <Column columns={6}>
                    <Headline type={'h1'} hasBorder={true}>
                        {t('domain.explore.continue-dive.title')}
                    </Headline>

                    <Text asMarkdown={true}>{t('domain.explore.continue-dive.text')}</Text>

                    <Button buttonType={'fillup'} onClick={() => navigate('/dive/scenario')}>
                        {t('domain.explore.waiting-room.button')}
                    </Button>
                </Column>
            </Grid>
        </>
    );
};

export default ContinueDive;
