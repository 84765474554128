import './statistic-card.scss';
import MermaidIcon from '../../../../shared-kernel/assets/icons/explore/BottomTime-30px.svg';
import SnorkelerIcon from '../../../../shared-kernel/assets/icons/explore/Snorkler-30px.svg';
import BottleIcon from '../../../../shared-kernel/assets/icons/explore/Bottle-30px.svg';
import LearningIcon from '../../../../shared-kernel/assets/icons/explore/Perspective-30px.svg';
import Card from '../../../../shared-kernel/components/card/Card';
import Headline from '../../../../shared-kernel/components/headline/Headline';
import { useTranslation } from 'react-i18next';
import Text from '../../../../shared-kernel/components/text/Text';
import fmtMSS from '../../../../shared-kernel/services/fmtMMS';

type ValueCardProps = {
    value: number | string
    label: string
    icon: string
}

const ValueCard = (props: ValueCardProps) => {
    return (
        <Card className={'value-card'}>
            <img className={'value-card__icon'} src={props.icon} alt={'icon'} />
            <p className={'value-card__value'}>{props.value}</p>
            <Text>{props.label}</Text>
        </Card>
    );
};

type StatisticCardProps = {
    totalDiveTime: number
    ownDiveTime: number
    totalTypedChars: number
    totalAnswerCount: number
}

const StatisticCard = (props: StatisticCardProps) => {
    const { t } = useTranslation();

    return (
        <Card className={'statistic-card'}>
            <Headline type={'h2'}>{t('domain.explore.statistics.title')}</Headline>

            <div className={'statistic-card__wrapper'}>
                <ValueCard
                    value={fmtMSS(props.totalDiveTime)}
                    label={t('domain.explore.statistics.totalDiveTime')}
                    icon={MermaidIcon}
                />
                <ValueCard
                    value={fmtMSS(props.ownDiveTime)}
                    label={t('domain.explore.statistics.ownDiveTime')}
                    icon={SnorkelerIcon}
                />
                <ValueCard
                    value={props.totalTypedChars}
                    label={t('domain.explore.statistics.totalTypedChars')}
                    icon={BottleIcon}
                />
                <ValueCard
                    value={props.totalAnswerCount}
                    label={t('domain.explore.statistics.totalAnswerCount')}
                    icon={LearningIcon}
                />
            </div>
        </Card>
    );
};

export default StatisticCard;
