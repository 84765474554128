import { useContext } from 'react';
import { AppContext } from '../../../../application/contexts/AppContext';
import GetReadyImage from './../../assets/images/GetReadyIntro.svg';
import Headline from '../../../../shared-kernel/components/headline/Headline';
import Text from '../../../../shared-kernel/components/text/Text';
import { useTranslation } from 'react-i18next';
import Button from '../../../../shared-kernel/components/button/Button';
import { useNavigate } from 'react-router-dom';
import Grid from '../../../../shared-kernel/components/grid/Grid';
import Column from '../../../../shared-kernel/components/grid/Column';

const GetReady = () => {
    const { User, Mission } = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const clickHandler = () => {
        navigate('/dive/scenario');
    };

    return (
        <Grid>
            <Column columns={6}>
                <img className={'resized-img'} src={GetReadyImage} alt={''} />
            </Column>
            <Column columns={6}>
                <Headline type={'h1'} hasBorder={true}>
                    {t('domain.intro.get-ready.title').replace('###USERNAME###', User?.firstName)}
                </Headline>

                <Text asMarkdown={true}>{Mission?.translations[i18n.language]?.getReadyText}</Text>

                <Button buttonType={'fillup'} onClick={clickHandler}>{t('domain.intro.get-ready.button')}</Button>
            </Column>
        </Grid>
    );
};

export default GetReady;
