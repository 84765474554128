import Headline from '../../../../shared-kernel/components/headline/Headline';
import { useTranslation } from 'react-i18next';
import Text from '../../../../shared-kernel/components/text/Text';
import BoatImage from '../../assets/images/ftu.svg';
import Grid from '../../../../shared-kernel/components/grid/Grid';
import Column from '../../../../shared-kernel/components/grid/Column';

const ParticipantLink = () => {
    const { t } = useTranslation();

    return (
        <Grid>
            <Column columns={6}>
                <img className={'resized-img'} src={BoatImage} alt={''} />
            </Column>
            <Column columns={6}>
                <Headline type={'h1'} hasBorder={true}>{t('domain.registration.participation-link.title')}</Headline>

                <Text asMarkdown={true}>
                    {t('domain.registration.participation-link.text')}
                </Text>
            </Column>
        </Grid>
    );
};

export default ParticipantLink;
