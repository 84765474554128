import Grid from '../../../../shared-kernel/components/grid/Grid';
import Column from '../../../../shared-kernel/components/grid/Column';
import { useTranslation } from 'react-i18next';
import OutroImage from '../../assets/images/DiveOutro.svg';
import Headline from '../../../../shared-kernel/components/headline/Headline';
import Text from '../../../../shared-kernel/components/text/Text';
import Button from '../../../../shared-kernel/components/button/Button';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../../application/contexts/AppContext';

const WaitingRoom = () => {
    const { dives, User } = useContext(AppContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const showBackToDiveButton = dives.length > 1 && User!.currentDive < dives.length;

    return (
        <Grid>
            <Column columns={6}>
                <img className={'resized-img'} src={OutroImage} alt={''} />
            </Column>
            <Column columns={6}>
                <Headline type={'h1'} hasBorder={true}>
                    {t('domain.explore.waiting-room.title')}
                </Headline>

                <Text asMarkdown={true}>{t('domain.explore.waiting-room.text')}</Text>

                {showBackToDiveButton &&
                    <Button buttonType={'fillup'} onClick={() => navigate('/dive/scenario')}>
                        {t('domain.explore.waiting-room.button')}
                    </Button>}
            </Column>
        </Grid>
    );
};

export default WaitingRoom;
