import { createContext, ReactNode, useContext, useState } from 'react';
import RegistrationData from '../value-objects/RegistrationData';
import Users from '../repositories/Users';
import { AppContext } from '../../../application/contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

type RegistrationContextType = {
    updateLocale: (locale: string) => void
    createUser: (data: RegistrationData, errorCallback: (errors: any) => void) => void;
}

const defaultRegistrationContextValue: RegistrationContextType = {
    updateLocale: (locale: string) => {
    },
    createUser: (data: RegistrationData, errorCallback: (errors: any) => void) => {
    },
};

export const RegistrationContext = createContext<RegistrationContextType>(defaultRegistrationContextValue);

export const RegistrationContextProvider = ({ children }: { children: ReactNode }) => {
    const { Mission } = useContext(AppContext);
    const [registrationData, setRegistrationData] = useState<RegistrationData>({
        firstName: '',
        email: '',
    });

    const navigate = useNavigate();

    const updateLocale = (locale: string) => {
        setRegistrationData({ ...registrationData, locale: locale });
    };

    const createUserMutation = useMutation({ mutationFn: Users.add });

    const createUser = async (regData: RegistrationData, errorCallback: (errors: any) => void) => {
        if (undefined === registrationData.locale) {
            regData.locale = Mission?.locales[0];
        }

        try {
            await createUserMutation.mutateAsync({ ...registrationData, ...regData });

            navigate('/registration/participant-link');
        } catch (error: any) {
            errorCallback(error.response.data.errors);
        }
    };

    const value: RegistrationContextType = {
        updateLocale,
        createUser,
    };

    return <RegistrationContext.Provider value={value}>{children}</RegistrationContext.Provider>;
};
