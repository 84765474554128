import { Outlet, useNavigate } from 'react-router-dom';
import { RegistrationContextProvider } from './contexts/RegistrationContext';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../application/contexts/AppContext';

const Root = () => {
    const { Mission, User } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (undefined === User) {
            if (1 === Mission?.locales.length) {
                navigate('/registration/welcome');
            } else {
                navigate('/registration/language-selection');
            }
        }
    }, [User, Mission, navigate]);

    return <>
        <RegistrationContextProvider>
            <Outlet />
        </RegistrationContextProvider>
    </>;
};

export default Root;
