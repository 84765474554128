import axios from 'axios';

const axiosClient = axios.create({
    baseURL: 'https://demo-api.branddive.de/api',
    headers: {
        'Content-type': 'application/json',
    },
});

export { axiosClient };
