import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Grid from '../../../../shared-kernel/components/grid/Grid';
import Column from '../../../../shared-kernel/components/grid/Column';
import OutroImage from '../../../explore/assets/images/DiveOutro.svg';
import Headline from '../../../../shared-kernel/components/headline/Headline';
import Text from '../../../../shared-kernel/components/text/Text';
import Button from '../../../../shared-kernel/components/button/Button';
import { useContext } from 'react';
import { AppContext } from '../../../../application/contexts/AppContext';

const Continue = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { User, dives } = useContext(AppContext);

    return (
        <Grid>
            <Column columns={6}>
                <img className={'resized-img'} src={OutroImage} alt={''} />
            </Column>
            <Column columns={6}>
                <Headline type={'h1'} hasBorder={true}>
                    {t('domain.dive.continue-dive.title')}
                </Headline>

                <Text asMarkdown={true}>
                    {t('domain.dive.continue-dive.text').replace('###DIVE_OF_DIVE###', `${User!.currentDive}/${dives.length}`)}
                </Text>

                <Button buttonType={'fillup'} onClick={() => navigate('/dive/scenario')}>
                    {t('domain.explore.waiting-room.button')}
                </Button>
            </Column>
        </Grid>
    );
}

export default Continue;
