import { PropsWithChildren } from 'react';

type ColumnProps = {
    columns?: 12 | 11 | 6 | 1
    offset?: 3
}

const Column = (props: PropsWithChildren<ColumnProps>) => {
    let classes = `column-${props.columns ?? 12}`;
    if (props.offset) {
        classes = `offset-${props.offset}-column-${props.columns ?? 12}`;
    }

    return (
        <div className={classes}>
            {props.children}
        </div>
    );
};

export default Column;
