import App from '../App';
import Loading from './pages/loading/Loading';
import React from 'react';
import Error from './pages/error/Error';
import { RouteObject } from 'react-router-dom';
import RegistrationRoutes from '../domain/registration/routes';
import IntroRoutes from '../domain/intro/routes';
import DiveRoutes from '../domain/dive/routes';
import ExploreRoutes from '../domain/explore/routes';

const AppRoutes: RouteObject[] = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '',
                element: <Loading />,
            },
            RegistrationRoutes,
            IntroRoutes,
            DiveRoutes,
            ExploreRoutes,
        ],
        errorElement: <Error />,
    },
];

export default AppRoutes;
