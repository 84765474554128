import { useTranslation } from 'react-i18next';
import './navigation.scss';
import './fraction.scss';
import { CSSProperties, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

type FractionProps = {
    top: number
    bottom: number
}

const Fraction = (props: FractionProps) => {
    const top = (props.top + 1 >= props.bottom ? props.bottom : props.top + 1);

    return (
        <span className='fraction'>
            <span className='fraction__character fraction__character--top'>{top}</span>
            <span className='fraction__character'>{props.bottom}</span>
	    </span>
    );
};

const NaviItem = (props: PropsWithChildren<{ isActive: boolean, isDisabled: boolean, onClick: () => void }>) => {
    const classes = classNames('side-nav__list-item', {
        'side-nav__list-item--locked': props.isDisabled,
        'side-nav__list-item--active': props.isActive && !props.isDisabled,
    });

    const clickHandler = () => {
        if (props.isActive || props.isDisabled) {
            return;
        }

        props.onClick();
    };

    return (
        <li className={classes} onClick={() => clickHandler()}>
            {props.children}
        </li>
    );
};

const Navigation = () => {
    const { t } = useTranslation();
    const [indicatorStyles, setIndicatorStyles] = useState<CSSProperties>({
        left: '0px',
        width: '0px',
    });
    const { User, dives } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();

    const moveIndicator = () => {
        const element: HTMLElement | null = document.querySelector('.side-nav__list-item--active');

        if (!element) {
            return;
        }

        setIndicatorStyles({
            left: element!.offsetLeft + 'px',
            width: element!.offsetWidth + 'px',
        });
    };

    useEffect(() => {
        moveIndicator();
    }, [location]);

    return (
        <div className={'side-nav-container'}>
            <nav className={'side-nav'}>
                <ul className={'side-nav__list'}>
                    <NaviItem
                        isActive={location.pathname.includes('explore')}
                        isDisabled={User!.currentDive === 0}
                        onClick={() => {
                            navigate(`/explore/${dives[0].id}`);
                        }}
                    >
                        {t('navigation.explore')}
                    </NaviItem>
                    <NaviItem
                        isActive={location.pathname.includes('dive')}
                        isDisabled={User!.currentDive >= dives.length && '/dive/outro' !== location.pathname}
                        onClick={() => {
                            navigate('/dive/scenario');
                        }}
                    >
                        {t('navigation.dive')}
                        <Fraction top={User!.currentDive} bottom={dives.length} />
                    </NaviItem>
                    <NaviItem
                        isActive={location.pathname.includes('intro')}
                        isDisabled={User!.currentDive > 0}
                        onClick={() => {
                            navigate('/intro');
                        }}
                    >
                        {t('navigation.get-ready')}
                    </NaviItem>
                    <span className={'side-nav_indicator side-nav_indicator--active'}
                          style={indicatorStyles}></span>
                </ul>
            </nav>
        </div>
    );
};

export default Navigation;
