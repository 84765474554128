import { axiosClient } from '../../../ApiConfig';
import Explore from '../entites/Explore';

const Explores = {
    findByDiveId: async (diveId: number) => {
        const response = await axiosClient.get<Explore>(`/dives/${diveId}/explore`, {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        });

        return response.data;
    },
};

export default Explores;
