import { useTranslation } from 'react-i18next';
import './navigation.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useContext } from 'react';
import { DiveContext, NavigationType } from '../../contexts/DiveContext';

type NavItemsProps = {
    navItem: NavigationType
}

const NaviItem = (props: NavItemsProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const classes = classNames('tab-nav__item', {
        'tab-nav__item--active': location.pathname === props.navItem.route,
        'tab-nav__item--disabled': props.navItem.disabled,
    });

    const clickHandler = () => {
        navigate(props.navItem.route);
    };

    return (
        <li className={classes} onClick={clickHandler}>
            {t(props.navItem.label)}
        </li>
    );
};

const Navigation = () => {
    const { navigation } = useContext(DiveContext);

    return (
        <ul className={'tab-nav'}>
            {navigation.map((navItem) => <NaviItem key={navItem.route} navItem={navItem} />)}
        </ul>
    );
};

export default Navigation;
