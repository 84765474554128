import { Mission } from '../../domain/registration/entities/Mission';
import { User } from '../../domain/registration/entities/User';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import Missions from '../../domain/registration/repositories/Missions';
import Translations from '../repositories/Translations';
import { useTranslation } from 'react-i18next';
import Users from '../../domain/registration/repositories/Users';
import Dive from '../../domain/dive/entities/Dive';
import Dives from '../../domain/dive/repositories/Dives';
import Loading from '../pages/loading/Loading';
import { useNavigate } from 'react-router-dom';
import Error from '../pages/error/Error';

type AppContextType = {
    User?: User
    Mission?: Mission
    dives: Dive[]
    showNavigation: boolean
    showTimer: boolean
    isLoading: boolean
    isExploreUnlocked: boolean
    updateShowNavigation: (showNavigation: boolean) => void
    updateShowTimer: (showTimer: boolean) => void
    isTimerRunning: boolean
    updateIsTimerRunning: (isTimerRunning: boolean) => void
    updateIsExploreUnlocked: (isExploreUnlocked: boolean) => void
}

const defaultAppContextValue: AppContextType = {
    User: undefined,
    Mission: undefined,
    showNavigation: false,
    showTimer: false,
    dives: [],
    isTimerRunning: false,
    isLoading: true,
    isExploreUnlocked: false,
    updateShowNavigation: (showNavigation: boolean): void => {
    },
    updateShowTimer: (showTimer: boolean): void => {

    },
    updateIsExploreUnlocked: (isExploreUnlocked: boolean): void => {
    },
    updateIsTimerRunning: (isTimerRunning: boolean): void => {
    },
};

export const AppContext = createContext<AppContextType>(defaultAppContextValue);

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
    const [showNavigation, setShowNavigation] = useState(false);
    const [showTimer, setShowTimer] = useState(false);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [isExploreUnlocked, setIsExploreUnlocked] = useState(false);
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const { data: Mission, ...missionLoader } = useQuery({
        queryKey: ['mission'],
        queryFn: Missions.findBySubdomain,
    });

    const { data: translations, ...translationLoader } = useQuery({
        queryKey: ['translations'],
        queryFn: Translations.load,
    });

    const { data: User, ...meLoader } = useQuery({
        queryKey: ['user'],
        queryFn: Users.me,
        retry: false,
        enabled: missionLoader.isSuccess && translationLoader.isSuccess,
    });

    const { data: dives, ...divesLoader } = useQuery({
        queryKey: ['dives'],
        queryFn: Dives.findByMission,
        enabled: meLoader.isSuccess,
        initialData: [],
    });

    const loginMutation = useMutation({
        mutationFn: Users.login,
        onSuccess: (data) => {
            localStorage.setItem('token', data.token);
            meLoader.refetch();

            navigate('/');
        },
    });

    if (translationLoader.isSuccess && missionLoader.isSuccess && !i18n.hasResourceBundle('en', 'translation')) {
        for (const [key, value] of Object.entries<{ translation: {} }>(translations)) {
            i18n.addResources(key, 'translation', value.translation);
        }

        i18n.changeLanguage(Mission?.locales[0]);

        const url = new URL(window.location.href);
        const authToken = url.searchParams.get('authToken');
        if (authToken) {
            loginMutation.mutate({
                auth_token: authToken,
                client_id: 'random-client-id',
            });
        }
    }

    if (meLoader.isError) {
        localStorage.removeItem('token');
    }

    const updateShowNavigation = (showNavigation: boolean) => {
        setShowNavigation(showNavigation);
    };

    const updateIsExploreUnlocked = (isExploreUnlocked: boolean) => {
        setIsExploreUnlocked(isExploreUnlocked);
    };

    const updateShowTimer = (showTimer: boolean) => {
        setShowTimer(showTimer);
    };

    const updateIsTimerRunning = (isTimerRunning: boolean) => {
        setIsTimerRunning(isTimerRunning);
    };

    const isLoading = missionLoader.isLoading
        || translationLoader.isLoading
        // || meLoader.isFetching
        || divesLoader.isFetching
        || loginMutation.isPending;

    useEffect(() => {
        if (undefined !== User) {
            i18n.changeLanguage(User.locale);
        }
    }, [User, i18n]);

    if (isLoading) {
        return <Loading />;
    }

    if (missionLoader.isError) {
        return <Error />
    }

    const value: AppContextType = {
        Mission,
        updateShowNavigation,
        User,
        showTimer,
        updateShowTimer,
        isExploreUnlocked,
        updateIsExploreUnlocked,
        dives,
        isLoading,
        showNavigation,
        isTimerRunning,
        updateIsTimerRunning,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
