import './feedback-card.scss';
import Card, { CardHeader } from '../../../../shared-kernel/components/card/Card';
import Learning from '../../../../shared-kernel/assets/icons/explore/Learning-30px.svg';
import { useTranslation } from 'react-i18next';
import Text from '../../../../shared-kernel/components/text/Text';
import Button from '../../../../shared-kernel/components/button/Button';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import Missions from '../../repositories/Missions';
import TextArea from '../../../../shared-kernel/components/input/TextArea';

type ValidateErrors = {
    content?: string
}

const FeedbackCard = () => {
    const [isActive, setIsActive] = useState(true);
    const { t } = useTranslation();
    const formMethods = useForm<{ content: string }>();
    const { handleSubmit } = formMethods;
    const [isSubmitted, setIsSubmitted] = useState(false);

    const feedbackMutation = useMutation({
        mutationFn: Missions.addFeedback,
        onSuccess: () => {
            setIsActive(false);
        },
        onError: (error: any) => {
            for (const [key, message] of Object.entries(error.response.data.errors)) {
                formMethods.setError(key as keyof ValidateErrors, { message: t(message) });
            }
            setIsSubmitted(false);
        },
    });

    const submit = (data: { content: string }) => {
        setIsSubmitted(true);
        feedbackMutation.mutate(data);
    };

    if (!isActive) {
        return null;
    }

    return (
        <Card className={'feedback-card'}>
            <CardHeader icon={Learning}>
                {t('domain.explore.feedback.title')}
            </CardHeader>
            <div>
                <div className={'card__body-text'}>
                    <Text>{t('domain.explore.feedback.text')}</Text>
                </div>
                <form onSubmit={handleSubmit(submit)}>
                    <FormProvider {...formMethods}>
                        <TextArea
                            name={'content'}
                            maxLength={280}
                            hasCounter={true}
                            defaultValue={''}
                            placeholder={t('domain.explore.feedback.from.field.content')}
                        />
                        <Button buttonType={'primary'} type={'submit'} isSubmitted={isSubmitted}>
                            {t('button.submit')}
                        </Button>
                    </FormProvider>
                </form>
            </div>
        </Card>
    );
};

export default FeedbackCard;
