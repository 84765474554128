import RegistrationData from '../value-objects/RegistrationData';
import { axiosClient } from '../../../ApiConfig';
import LoginData from '../value-objects/LoginData';
import { User } from '../entities/User';

const Users = {
    add: async (data: RegistrationData) => {
        const response = await axiosClient.post('/users', data);

        return response.data;
    },

    login: async (data: LoginData) => {
        const response = await axiosClient.post<{ token: string }>('/login', data);

        return response.data;
    },

    me: async () => {
        const response = await axiosClient.get<User>('/me', {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        });

        return response.data;
    },

    updateDiveIndex: async (currentDive: number) => {
        const response = await axiosClient.patch('/users', { currentDive }, {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        });

        return response.data;
    },
};

export default Users;
