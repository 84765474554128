import React, { ComponentPropsWithRef } from 'react';
import './input.scss';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

type InputProps = ComponentPropsWithRef<'input'> & {
    autofocus?: boolean
    hasCounter?: boolean
    validation?: any
}

export default function Input({ hasCounter, ...props }: InputProps) {
    const { control, watch } = useFormContext();
    const rules = props.required ? { required: 'Pflichtfeld' } : {};

    let wrapperClasses: string = '';
    let counter: JSX.Element | null = null;

    if (hasCounter) {
        const maxLength = props.maxLength ?? 250;
        const len = watch(props.name as string)?.length ?? 0;
        const maxOfLen = maxLength - len;
        const widthOfProgressbar = (100 / maxLength) * len;

        wrapperClasses = classNames(
            'input',
            'input--growing',
            {
                'input--growing-filled': maxOfLen <= 0,
            },
        );

        counter = <><span className='input__counter'> {maxOfLen}</span><span className='input__progress'
                                                                             style={{ width: `${widthOfProgressbar}%` }}></span></>;
    } else {
        wrapperClasses = 'input';
    }

    return (
        <Controller
            control={control}
            name={props.name as string}
            defaultValue={props.defaultValue}
            rules={rules}
            render={({ field, fieldState: { error } }) => (
                <div className={`${wrapperClasses} ${error && 'input__field--error'}`}>
                    {counter}
                    <input
                        className={`input__field`}
                        {...field}
                        autoComplete={'off'}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        maxLength={props.maxLength}
                        placeholder={props.placeholder}
                    />

                    {error && <p className='errorText'>{error.message?.toUpperCase()}</p>}
                </div>
            )}
        />
    );
}
