import './timer.scss';
import { Circle } from 'progressbar.js';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountdown } from 'usehooks-ts';
import fmtMMS from '../../../shared-kernel/services/fmtMMS';
import { AppContext } from '../../contexts/AppContext';


const Timer = () => {
    const { isTimerRunning, dives, User } = useContext(AppContext);
    const [timer, setTimer] = useState<any>();
    const { t } = useTranslation();
    const diveTimer = dives[User!.currentDive]!.timer;
    const [count, { startCountdown, stopCountdown }] = useCountdown({
        countStart: diveTimer,
        intervalMs: 1000,
    });

    useEffect(() => {
        if (timer === undefined) {
            setTimer(new Circle('#timer', {
                duration: diveTimer * 1000,
                color: '#aaa',
                trailColor: '#1d1dff',
                strokeWidth: 2.5,
                trailWidth: 2.5,
                text: {
                    value: fmtMMS(count),
                    className: 'timer__label',
                },
            }));
            const timerElement = document.getElementById('timer');
            if (timerElement!.children.length >= 2) {
                timerElement!.removeChild(timerElement!.children[0]);
            }
        }
    }, [timer, setTimer, diveTimer, count]);

    useEffect(() => {
        timer?.setText(fmtMMS(count));
    }, [timer, count]);

    useEffect(() => {
        if (timer === undefined) {
            return;
        }

        if (isTimerRunning) {
            startCountdown();
            timer.animate(-1.0);
        } else {
            timer.stop();
            stopCountdown();
        }
    }, [timer, isTimerRunning, startCountdown, stopCountdown]);

    return (
        <>
            <div className={'user-menu__timer-wrapper'}>
                <div id={'timer'}></div>

                <p className={'user-menu__timer-label'}>
                    {count === diveTimer && t('domain.dive.timer.ready')}
                    {count > 0 && count < diveTimer && t('domain.dive.timer.dive')}
                    {count === 0 && t('domain.dive.timer.done')}
                </p>
            </div>
        </>
    );
};

export default Timer;
