import Card, { CardHeader } from '../../../../shared-kernel/components/card/Card';
import { useTranslation } from 'react-i18next';
import ExcellentIcon from '../../../../shared-kernel/assets/icons/explore/Correct.svg';
import { Answer } from '../../../dive/entities/Answer';
import Tagline from '../../../../shared-kernel/components/tagline/Tagline';

type ExcellentAnswerCardProps = {
    answer: Answer
}

const ExcellentAnswerCard = (props: ExcellentAnswerCardProps) => {
    const { t } = useTranslation();

    return (
        <Card>
            <CardHeader icon={ExcellentIcon}>
                {t('domain.explore.excellentAnswer.title')}
            </CardHeader>

            <div>
                <p className={'card__body-text'}>
                    {props.answer.text}
                </p>

                {props.answer.autor && <Tagline>{props.answer.autor}</Tagline>}
            </div>
        </Card>
    );
};

export default ExcellentAnswerCard;
