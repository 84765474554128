import './vote.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import Answers from '../../repositories/Answers';
import Headline from '../../../../shared-kernel/components/headline/Headline';
import React, { ReactElement, useContext, useState } from 'react';
import { DiveContext } from '../../contexts/DiveContext';
import Button from '../../../../shared-kernel/components/button/Button';
import { Answer } from '../../entities/Answer';
import { useTranslation } from 'react-i18next';
import Dialog, { ButtonBar } from '../../../../shared-kernel/components/dialog/Dialog';
import VoteCard from '../../components/vote-card/VoteCard';
import Loading from '../../../../application/pages/loading/Loading';

const Vote = () => {
    const { dive, nextStep } = useContext(DiveContext);
    const { t } = useTranslation();
    const [showDialog, setShowDialog] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState<Answer | null>(null);

    const votableAnswersLoader = useQuery({
        queryKey: ['votableAnswers'],
        queryFn: () => Answers.findVotableByDiveId(dive!.id),
    });

    const voteMutation = useMutation({
        mutationFn: () => Answers.vote(selectedAnswer!.id),
        onSuccess: () => {
            nextStep();
        },
    });

    const showDialogHandler = () => {
        setShowDialog(true);
    };

    const hideDialogHandler = () => {
        setShowDialog(false);
    };

    const skipVoteHandler = () => {
        nextStep();
    };

    const voteHandler = () => {
        voteMutation.mutate();
    };

    const selectAnswerHandler = (answer: Answer) => {
        setSelectedAnswer(answer);
    };

    const answers: ReactElement[] | undefined = votableAnswersLoader.data?.map((answer: Answer) => {
        return (
            <VoteCard
                key={answer.id}
                answer={answer}
                isSelected={answer === selectedAnswer}
                onSelect={selectAnswerHandler}
                onVote={voteHandler}
            />
        );
    });

    return (
        <>
            <Headline type={'h1'} hasBorder={false}>
                {dive?.voteTitle}
            </Headline>

            {

                answers ?

                    <div className={'card__column-wrapper'}>
                        <div className={'card__column'}>
                            {answers!.map((card, index: number) => (index % 2 === 0) ? card : null)}
                        </div>
                        <div className={'card__column'}>
                            {answers!.map((card, index: number) =>  (index % 2 !== 0) ?  card : null)}
                        </div>
                    </div>

                    :
                    <Loading />

            }

            <Button buttonType={'secondary'} onClick={showDialogHandler}>
                {t('button.skip-vote')}
            </Button>

            <Dialog showDialog={showDialog}>
                <Headline type={'h2'} hasMarginTop={true}>
                    {t('domain.dive.vote-skip-dialog.title')}
                </Headline>

                <ButtonBar>
                    <Button buttonType={'secondary'} onClick={hideDialogHandler}>
                        {t('button.back')}
                    </Button>

                    <Button buttonType={'fillup'} onClick={skipVoteHandler}>
                        {t('domain.dive.vote-skip-dialog.button')}
                    </Button>
                </ButtonBar>
            </Dialog>
        </>
    );
};

export default Vote;
