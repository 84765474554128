import './registration-form.scss';
import Headline from '../../../../shared-kernel/components/headline/Headline';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import Input from '../../../../shared-kernel/components/input/Input';
import Button from '../../../../shared-kernel/components/button/Button';
import RegistrationData from '../../value-objects/RegistrationData';
import { useContext, useState } from 'react';
import { RegistrationContext } from '../../contexts/RegistrationContext';
import Grid from '../../../../shared-kernel/components/grid/Grid';
import Column from '../../../../shared-kernel/components/grid/Column';

type ValidateErrors = {
    firstName?: string
    email?: string
}

export default function RegistrationForm() {
    const { t } = useTranslation();
    const formMethods = useForm<RegistrationData>();
    const { handleSubmit } = formMethods;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { createUser } = useContext(RegistrationContext);

    const submitHandler = (data: RegistrationData) => {
        setIsSubmitted(true);
        createUser(data, (errors: ValidateErrors) => {
            for (const [key, message] of Object.entries(errors)) {
                formMethods.setError(key as keyof ValidateErrors, { message: t(message) });
            }

            setIsSubmitted(false);
        });
    };

    return (
        <>
            <Headline type={'h3'}>{t('domain.registration.form.subTitle')}</Headline>

            <Headline type={'h1'} hasMarginTop={true} hasBorder={true}>{t('domain.registration.form.title')}</Headline>

            <FormProvider {...formMethods}>
                <form className={'registration-form'} onSubmit={handleSubmit(submitHandler)}>
                    <Grid>
                        <Column columns={6}>
                            <Input name={'firstName'} type={'text'}
                                   placeholder={t('domain.registration.form.field.name')}
                                   defaultValue={''} />
                        </Column>
                        <Column columns={6}>
                            <Input name={'email'} type={'email'} placeholder={t('domain.registration.form.field.email')}
                                   defaultValue={''} />
                        </Column>
                    </Grid>
                    <Button
                        type={'submit'}
                        buttonType={'primary'}
                        isSubmitted={isSubmitted}
                    >
                        {t('button.submit')}
                    </Button>
                </form>
            </FormProvider>
        </>
    );
}
