import Column from '../../../../shared-kernel/components/grid/Column';
import ExploreOutroImage from '../../assets/images/ExploreIntro.svg';
import Headline from '../../../../shared-kernel/components/headline/Headline';
import Text from '../../../../shared-kernel/components/text/Text';
import Button from '../../../../shared-kernel/components/button/Button';
import Grid from '../../../../shared-kernel/components/grid/Grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../../application/contexts/AppContext';

const Outro = () => {
    const { dives } = useContext(AppContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    return(
        <Grid>
            <Column columns={6}>
                <img className={'resized-img'} src={ExploreOutroImage} alt={''} />
            </Column>
            <Column columns={6}>
                <Headline type={'h1'} hasBorder={true}>
                    {t('domain.dive.outro.title')}
                </Headline>

                <Text asMarkdown={true}>
                    {t('domain.dive.outro.text')}
                </Text>

                <Button buttonType={'fillup'} onClick={() => navigate(`/explore/${dives[0].id}`)}>
                    {t('domain.dive.outro.button')}
                </Button>
            </Column>
        </Grid>
    );
}

export default Outro;
