import AnswerData from '../value-objects/AnswerData';
import { axiosClient } from '../../../ApiConfig';
import { Answer } from '../entities/Answer';

const Answers = {
    add: async (data: AnswerData) => {
        const response = await axiosClient.post('/answers', data, {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        });

        return response.data;
    },
    findVotableByDiveId: async (diveId: number) => {
        const response = await axiosClient.get<Answer[]>(`/dives/${diveId}/answers/votable`, {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        });

        return response.data;
    },
    vote: async (id: number) => {
        const response = await axiosClient.put(`/answers/${id}/vote`, null, {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        });

        return response.data;
    },
};

export default Answers;
