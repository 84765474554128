import { axiosClient } from '../../ApiConfig';

const Translations = {
    load: async () => {
        const response = await axiosClient.get('/translations');

        return response.data;
    },
};

export default Translations;
