import { PropsWithChildren } from 'react';
import './text.scss';
import classNames from 'classnames';
import Markdown from 'react-markdown';

type TextProps = {
    margin?: 's',
    asMarkdown?: boolean
}

export default function Text(props: PropsWithChildren<TextProps>) {
    const classes = classNames('text-wrapper', { 'text-wrapper--margin-s': 's' === props.margin });

    const text = props.asMarkdown ? <Markdown className={'text'}>{props.children as string}</Markdown> :
        <p className={'text'}>{props.children}</p>;

    return (
        <div className={classes}>
            {text}
        </div>
    );
}
