import classNames from 'classnames';
import './loading.scss';

type LoadingProps = {
    isTransparent?: boolean
}

export default function Loading({ isTransparent }: LoadingProps) {
    const iconClass = classNames('loading__indicator', { 'loading__indicator--transparent': isTransparent });

    return (
        <div className={'loading-wrapper'}>
            <i className={iconClass}></i>
        </div>
    );
}
