import './own-answer-card.scss';
import Card, { CardHeader } from '../../../../shared-kernel/components/card/Card';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserIcon from '../../../../shared-kernel/assets/icons/explore/User.svg';
import ArrowIcon from '../../../../shared-kernel/assets/icons/ArrowSmall.svg';
import Text from '../../../../shared-kernel/components/text/Text';
import classNames from 'classnames';
import { Answer } from '../../../dive/entities/Answer';

type OwnAnswerCardProps = {
    answer: Answer
}

const OwnAnswerCard = (props: OwnAnswerCardProps) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const arrowClasses = classNames('own-answer-card__toggle__icon', {
        'card__toggle__icon--active': isOpen,
    });

    return (
        <Card className={'own-card'}>
            <div className={'own-card__header'} onClick={() => setIsOpen(!isOpen) }>
                <CardHeader icon={UserIcon}>
                    {t('domain.explore.ownAnswer.title')}
                </CardHeader>
                <img className={arrowClasses} src={ArrowIcon} alt={''} />
            </div>
            {isOpen &&
                <div>
                    <div className={'card__body-text'}>
                        <Text>{props.answer.text}</Text>
                    </div>
                </div>
            }
        </Card>
    );
};

export default OwnAnswerCard;
