import './data-protection-notice.scss';
import UserShieldIcon from '../../assets/icons/user_shield.png';
import Text from '../../../../shared-kernel/components/text/Text';
import { useTranslation } from 'react-i18next';

const DataProtectionNotice = () => {
    const { t } = useTranslation();

    return (
        <div className={'data-protection-notice'}>
            <div className={'data-protection-notice__message'}>
                <Text asMarkdown={true}>
                    {t('domain.dive.data-protection-notice.text')}
                </Text>
            </div>

            <div className={'data-protection-notice__trigger'}>
                <img src={UserShieldIcon} alt={''}/>
                {t('domain.dive.data-protection-notice.trigger')}
            </div>
        </div>
    );
}

export default DataProtectionNotice;
