import './welcome.scss';
import Headline from '../../../../shared-kernel/components/headline/Headline';
import { useTranslation } from 'react-i18next';
import DiveIntroImage from '../../assets/images/DiveIntro.svg';
import Text from '../../../../shared-kernel/components/text/Text';
import Button from '../../../../shared-kernel/components/button/Button';
import { useNavigate } from 'react-router-dom';
import Grid from '../../../../shared-kernel/components/grid/Grid';
import Column from '../../../../shared-kernel/components/grid/Column';

export default function Welcome() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const clickHandler = () => {
        navigate('/registration/create-account');
    };

    return (
        <Grid>
            <Column columns={6}>
                <img className={'resized-img'} src={DiveIntroImage} alt={''} />
            </Column>
            <Column columns={6}>
                <Headline type={'h1'} hasBorder={true}>{t('domain.registration.welcome.title')}</Headline>
                <Text asMarkdown={true}>
                    {t('domain.registration.welcome.text')}
                </Text>

                <Button
                    buttonType={'fillup'}
                    onClick={clickHandler}
                >
                    {t('domain.registration.welcome.button')}
                </Button>
            </Column>
        </Grid>
    );
}
