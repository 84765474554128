import { DiveContextProvider } from './contexts/DiveContext';
import { Outlet } from 'react-router-dom';
import Navigation from './components/navigation/Navigation';

const Root = () => {
    return (
        <DiveContextProvider>
            <Navigation />

            <Outlet />
        </DiveContextProvider>
    );
};

export default Root;
