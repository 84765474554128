import { useRef } from 'react';
import './radio.scss';
import classNames from 'classnames';

type RadioProps = {
    onChange: (value: string) => void
    isDisabled?: boolean
    value: string
    groupName: string
    isChecked: boolean
    label: string
}

export default function Radio(props: RadioProps) {
    const radioRef = useRef<HTMLInputElement>(null);

    const inputClasses = classNames('radio', {
        'input--disabled': props.isDisabled,
        'radio__label--active': props.isChecked,
    });

    const labelClasses = classNames('radio__label', {
        'radio__label--active': props.isChecked,
    });

    const onChangeHandler = function() {
        console.log('<Radio>.onChangeHandler');

        props.onChange(radioRef.current?.value ?? '');
    };

    return (
        <div className={inputClasses} onClick={onChangeHandler}>
            <input
                className={`radio__input`}
                type='radio'
                ref={radioRef}
                value={props.value}
                name={props.groupName}
                defaultChecked={props.isChecked}
                disabled={props.isDisabled}
            />
            <label className={labelClasses}>
                {props.label}
            </label>
        </div>
    );
}
