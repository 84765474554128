import { PropsWithChildren } from 'react';

const Grid = (props: PropsWithChildren) => {
    return (
        <div className={'m-grid'}>
            <div className={'grid-container'}>
                {props.children}
            </div>
        </div>
    );
};

export default Grid;
