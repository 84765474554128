import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Dive from '../entities/Dive';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../application/contexts/AppContext';
import Users from '../../registration/repositories/Users';

export type NavigationType = {
    route: string
    label: string
    disabled: boolean
}

type DiveContextType = {
    dive?: Dive
    nextStep: () => void
    navigation: NavigationType[]
}

enum PageIndex {
    SCENARIO = 0,
    EXAMPLE = 1,
    ACTION = 2,
    VOTE = 3,
}

const defaultDiveContextValue: DiveContextType = {
    dive: undefined,
    nextStep: () => {
    },
    navigation: [
        {
            route: '/dive/scenario',
            label: 'domain.dive.navigation.scenario',
            disabled: false,
        },
        {
            route: '/dive/example',
            label: 'domain.dive.navigation.example',
            disabled: true,
        },
        {
            route: '/dive/action',
            label: 'domain.dive.navigation.action',
            disabled: true,
        },
        {
            route: '/dive/vote',
            label: 'domain.dive.navigation.vote',
            disabled: true,
        },
    ],
};

export const DiveContext = createContext<DiveContextType>(defaultDiveContextValue);

export const DiveContextProvider = ({ children }: { children: ReactNode }) => {
    const { User, dives, updateIsExploreUnlocked } = useContext(AppContext);
    const navigate = useNavigate();
    const [navigation, setNavigation] = useState(defaultDiveContextValue.navigation);
    const location = useLocation();
    const queryClient = useQueryClient();

    useEffect(() => {
        if ('/dive/scenario' === location.pathname) {
            navigation[PageIndex.SCENARIO].disabled = false;
            navigation[PageIndex.VOTE].disabled = true;
        }
    }, [location, navigation]);

    const userMutation = useMutation({
        mutationFn: Users.updateDiveIndex,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user'] });
        },
    });

    const nextDive = () => {
        const currentDiveKey = User!.currentDive;
        const nextDiveKey = currentDiveKey + 1;

        userMutation.mutate(nextDiveKey);
        updateIsExploreUnlocked(true);
        if (dives.length <= nextDiveKey) {
            navigate('/dive/outro');
        } else {
            navigate('/dive/scenario');
        }
    };

    const nextStep = () => {
        switch (location.pathname) {
            case '/dive/scenario':
                navigate('/dive/example');
                navigation[PageIndex.EXAMPLE].disabled = false;
                setNavigation(navigation);
                break;
            case '/dive/example':
                navigation[PageIndex.ACTION].disabled = false;
                navigate('/dive/action');
                break;
            case '/dive/action':
                navigation[PageIndex.SCENARIO].disabled = true;
                navigation[PageIndex.EXAMPLE].disabled = true;
                navigation[PageIndex.ACTION].disabled = true;
                navigation[PageIndex.VOTE].disabled = false;
                navigate('/dive/vote');
                break;
            case '/dive/vote':
                nextDive();
                break;
        }
    };

    const value: DiveContextType = {
        dive: dives[User!.currentDive],
        nextStep,
        navigation,
    };

    return <DiveContext.Provider value={value}>{children}</DiveContext.Provider>;
};
