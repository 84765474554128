import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import './user-card.scss';
import UserIcon from '../../../shared-kernel/assets/icons/ICOUser.svg';
import Timer from '../timer/Timer';

const UserCard = () => {
    const { User, showTimer } = useContext(AppContext);

    return (
        <div className={'user-menu'}>
            <img className={'user-menu__icon'} src={UserIcon} alt={''} />

            <p className={'user-menu__user-name user-name--active'}>{User?.firstName}</p>

            {showTimer && <Timer />}
        </div>
    );
};

export default UserCard;
