import { RouteObject } from 'react-router-dom';
import Root from './Root';
import Scenario from './pages/scenario/Scenario';
import Example from './pages/example/Example';
import Action from './pages/action/Action';
import React from 'react';
import Vote from './pages/vote/Vote';
import Outro from './pages/outro/Outro';
import Continue from './pages/continue/Continue';

const DiveRoutes: RouteObject = {
    path: 'dive',
    element: <Root />,
    children: [
        {
            path: 'scenario',
            element: <Scenario />,
        },
        {
            path: 'example',
            element: <Example />,
        },
        {
            path: 'action',
            element: <Action />,
        },
        {
            path: 'vote',
            element: <Vote />,
        },
        {
            path: 'outro',
            element: <Outro />,
        },
        {
            path: 'continue',
            element: <Continue />
        }
    ],
};

export default DiveRoutes;
