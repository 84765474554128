import { axiosClient } from '../../../ApiConfig';
import missionSlug from '../../../shared-kernel/services/missionSlug';

const Dives = {
    findByMission: async () => {
        const slug = missionSlug();
        const response = await axiosClient.get(`/missions/${slug}/dives`, {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        });

        return response.data;
    },
};

export default Dives;
