import './navigation.scss';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../../application/contexts/AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowIcon from '../../../../shared-kernel/assets/icons/ArrowSmall.svg';

const Navigation = () => {
    const { t } = useTranslation();
    const { dives } = useContext(AppContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const currentDiveIndex = dives.findIndex((dive) => dive.id === parseInt(id!));

    const moveBackHandler = () => {
        let newDiveIndex = currentDiveIndex - 1;

        if (newDiveIndex < 0) {
            newDiveIndex = dives.length - 1;
        }

        navigate(`/explore/${dives[newDiveIndex].id}`);
    };

    const moveForwardHandler = () => {
        let newDiveIndex = currentDiveIndex + 1;

        if (newDiveIndex >= dives.length) {
            newDiveIndex = 0;
        }

        navigate(`/explore/${dives[newDiveIndex].id}`);
    };

    return (
        <ul className={'tab-nav'}>
            <li className={'tab-nav__item tab-nav__item--active'}>
                {t('domain.explore.navigation.scenario')}
            </li>
            <li className={'tab-nav__item'}>
                <img className={'tab-nav__icon--back'} src={ArrowIcon} onClick={moveBackHandler} alt={''} />
                {t('domain.explore.navigation.dive')} {currentDiveIndex + 1}/{dives.length}
                <img className={'tab-nav__icon--forward'} src={ArrowIcon} onClick={moveForwardHandler} alt={''} />
            </li>
        </ul>
    );
};

export default Navigation;
