import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import './App.css';
import { AppContextProvider } from './application/contexts/AppContext';
import Layout from './application/components/Layout';

function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                refetchInterval: false,
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            <AppContextProvider>
                <Layout />
            </AppContextProvider>
        </QueryClientProvider>
    );
}

export default App;
