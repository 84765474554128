import { PropsWithChildren } from 'react';
import './headline.scss';
import classNames from 'classnames';

type HeadlineProps = {
    type: 'h1' | 'h2' | 'h3' | 'h4'
    hasBorder?: boolean
    hasMarginTop?: boolean
    noOverflow?: boolean
}

export default function Headline(props: PropsWithChildren<HeadlineProps>) {
    const classes = classNames(
        'headline',
        `headline--${props.type}`,
        {
            'headline--border': props.hasBorder,
            'headline--no-overflow': props.noOverflow,
            'headline--margin-top': props.hasMarginTop,
        },
    );

    let headline = {
        h1: <h1
            className={classes}>{props.children}</h1>,
        h2: <h2
            className={classes}>{props.children}</h2>,
        h3: <h3
            className={'headline headline--h3'}>{props.children}</h3>,
        h4: <h4
            className={'headline headline--h4'}>{props.children}</h4>,
    }[props.type];

    return (
        <div className={'headline-wrapper'}>
            {headline}
        </div>
    );
}
