import FeedbackData from '../value-objects/FeedbackData';
import { axiosClient } from '../../../ApiConfig';
import missionSlug from '../../../shared-kernel/services/missionSlug';

const Missions = {
    addFeedback: async (data: FeedbackData) => {
        const slug = missionSlug();
        const response = await axiosClient.post(`/missions/${slug}/feedback`, data, {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        });

        return response.data;
    },
};

export default Missions;
