import { ComponentPropsWithoutRef, MouseEvent } from 'react';
import './button.scss';
import classNames from 'classnames';

type ButtonProps = ComponentPropsWithoutRef<'button'> & {
    buttonType: 'primary' | 'secondary' | 'fillup' | 'link'
    isSubmitted?: boolean
}

const Button = ({ buttonType, isSubmitted, ...props }: ButtonProps) => {
    const classes = classNames(
        'button',
        'button--' + buttonType,
        {
            'button--disabled': props.disabled,
            'button--submitted': isSubmitted,
        },
    );

    const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
        if (props.onClick) {
            props.onClick(event);
        }
    };

    const submitIcon = 'submit' === props.type ? <span className={'button__icon'}>
    <svg width='26' height='26' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
                    d='M20.1573 24.9344C19.9317 24.9344 19.7048 24.88 19.4952 24.7698L12.9998 21.3549L6.50432 24.7698C6.02246 25.0231 5.44943 24.9817 5.00898 24.6617C4.56853 24.3417 4.35208 23.8095 4.44412 23.2729L5.68467 16.0401L0.429605 10.9178C0.0397411 10.5378 -0.0979388 9.98 0.0703197 9.46221C0.238527 8.94441 0.677755 8.5741 1.21653 8.49579L8.4787 7.44055L11.7265 0.85988C11.9674 0.371695 12.4553 0.0683594 12.9997 0.0683594C13.5442 0.0683594 14.0321 0.371644 14.273 0.85988L17.5208 7.4405L24.7829 8.49574C25.3217 8.57405 25.7609 8.94436 25.9292 9.46216C26.0974 9.97995 25.9598 10.5377 25.5699 10.9177L20.3149 16.0401L21.5555 23.2729C21.6475 23.8096 21.431 24.3417 20.9906 24.6617C20.7417 24.8425 20.4505 24.9344 20.1573 24.9344ZM1.66051 9.97888L7.33014 15.5054L5.99173 23.309L12.9998 19.6247L20.0078 23.309L18.6694 15.5054L24.3391 9.97888L16.5038 8.84037L12.9998 1.74043L9.49576 8.84037L1.66051 9.97888Z'
                    fill='white' />
				<path
                    d='M13 7.5725C13.4006 7.5725 13.7843 7.22031 13.7658 6.80676C13.7472 6.39188 13.4293 6.04102 13 6.04102C12.5995 6.04102 12.2158 6.39321 12.2343 6.80676C12.2529 7.22163 12.5707 7.5725 13 7.5725Z'
                    fill='white' />
				<path
                    d='M7.35855 12.4748C7.75908 12.4748 8.14282 12.1227 8.12429 11.7091C8.10571 11.2942 7.78782 10.9434 7.35855 10.9434C6.95802 10.9434 6.57428 11.2955 6.59281 11.7091C6.61139 12.124 6.92927 12.4748 7.35855 12.4748Z'
                    fill='white' />
				<path
                    d='M9.5358 18.9944C9.93633 18.9944 10.3201 18.6422 10.3015 18.2286C10.283 17.8138 9.96507 17.4629 9.5358 17.4629C9.13526 17.4629 8.75152 17.8151 8.77005 18.2286C8.78864 18.6435 9.10652 18.9944 9.5358 18.9944Z'
                    fill='white' />
				<path
                    d='M16.4285 19.0412C16.829 19.0412 17.2128 18.6891 17.1942 18.2755C17.1756 17.8606 16.8578 17.5098 16.4285 17.5098C16.028 17.5098 15.6442 17.862 15.6628 18.2755C15.6813 18.6904 15.9992 19.0412 16.4285 19.0412Z'
                    fill='white' />
				<path
                    d='M18.4606 12.4221C18.8611 12.4221 19.2449 12.0699 19.2263 11.6564C19.2077 11.2415 18.8899 10.8906 18.4606 10.8906C18.0601 10.8906 17.6763 11.2428 17.6949 11.6564C17.7134 12.0712 18.0313 12.4221 18.4606 12.4221Z'
                    fill='white' />
				<path
                    d='M13.0001 15.639C11.6489 15.639 10.5497 14.5398 10.5497 13.1887C10.5497 11.8375 11.6489 10.7383 13.0001 10.7383C14.3512 10.7383 15.4504 11.8375 15.4504 13.1887C15.4504 14.5398 14.3512 15.639 13.0001 15.639ZM13.0001 12.2698C12.4934 12.2698 12.0812 12.682 12.0812 13.1887C12.0812 13.6953 12.4934 14.1075 13.0001 14.1075C13.5067 14.1075 13.919 13.6953 13.919 13.1887C13.919 12.682 13.5067 12.2698 13.0001 12.2698Z'
                    fill='white' />
			</svg>
    </span> : null;

    return (
        <button
            className={classes}
            {...props}
            onClick={onClickHandler}
        >
            <span className='button__text'>{props.children}</span>
            {submitIcon}
        </button>
    );
};

export default Button;
