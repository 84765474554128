import { ComponentPropsWithoutRef, PropsWithChildren, useEffect, useRef } from 'react';
import './dialog.scss';

type DialogProps = ComponentPropsWithoutRef<'dialog'> & {
    showDialog: boolean
}

const Dialog = ({ showDialog, children, ...props }: DialogProps) => {
    const ref = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (showDialog) {
            ref.current?.showModal();
        } else {
            ref.current?.close();
        }
    }, [showDialog]);

    return (
        <dialog className={'dialog'} {...props} ref={ref}>
            {children}
        </dialog>
    );
};

const ButtonBar = (props: PropsWithChildren) => {
    return (
        <div className={'dialog__button-wrapper'}>
            {props.children}
        </div>
    );
};

export default Dialog;

export { ButtonBar };
